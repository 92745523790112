import {DatalakeElementItemId} from '@shared/dynamo_model';
import {env, IS_LOCALHOST_ENV} from '@shared/env_constants';
import {buildUrl} from '@shared/lib/domain_utils';
import {LocalEnvConstants} from '@shared/model/env_constants';
import {analyticsApiDomains} from '@shared/terraform';

export const ANALYTICS_DOMAIN = buildUrl({
  protocol: IS_LOCALHOST_ENV ? 'http' : 'https',
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  domain: analyticsApiDomains[0]!,
  port: IS_LOCALHOST_ENV ? (env as LocalEnvConstants).devPort : undefined,
});

export function generateGoogleAnalyticsSnippet(datalakeElementId: DatalakeElementItemId): string {
  return `
  function sendAnalytics(url) {
    const splitIndex = url.indexOf('?');
    if (splitIndex === -1) {
      return;
    }
    const match = /^https?:\\/\\/www.google-analytics.com\\/(?<analyticsPath>.*)/u.exec(
      url.slice(0, Math.max(0, splitIndex))
    );
    if (!match) {
      return;
    }
    fetch(\`${ANALYTICS_DOMAIN}/${datalakeElementId}/\${match[1]}\${url.slice(splitIndex)}\`)
      .catch(err => {
        throw err;
      });
  }
  
  function setupAnalytics() {
    // Intercept img elements
    (function (createElement) {
      document.createElement = function(
        tagName,
        options
      ) {
        const elem = createElement.call(document, tagName, options);
        if (tagName === 'img') {
          new MutationObserver(mutations => {
            for (const mutation of mutations) {
              sendAnalytics((mutation.target).src);
            }
          }).observe(elem, {attributeFilter: ['src']});
        }
        return elem;
      };
    })(document.createElement);
  
    // Intercept xhr calls
    const oldXhrOpen = XMLHttpRequest.prototype.open;
    XMLHttpRequest.prototype.open = function (...args) {
      sendAnalytics(args[1].toString());
      return oldXhrOpen.apply(this, args);
    };
  }
  
  setupAnalytics();
    `.trim();
}

export function generateGoogleAnalyticsSnippetUrl(
  datalakeElementId: DatalakeElementItemId
): string {
  return `${ANALYTICS_DOMAIN}/snippet?id=${datalakeElementId}`;
}

export function generateGoogleAnalyticsSnippetHeadTag(
  datalakeElementId: DatalakeElementItemId
): string {
  return `<script nonce="%%NONCE%%" defer src="${generateGoogleAnalyticsSnippetUrl(
    datalakeElementId
  )}"><\\/script>`;
}

const SNIPPET = `
try {
  const d=document,s=localStorage,k="onescale_ana=";
  let o=s.getItem(k);
  o||(
    o=btoa(Math.random()).slice(3),
    s.setItem(k,o)
  ),
  fetch(
    encodeURI(\`
      ${ANALYTICS_DOMAIN}/\${window.ANALYTICS_ID}/?
        cid=\${o}&
        pa=\${d.location.pathname}&
        dr=\${d.referrer}
    \`)
  ).catch(() => {})
} catch {}
`
  .split('\n')
  .map(l => l.trim())
  .join('');

export function generateAnalyticsScript(): string {
  return SNIPPET;
}
