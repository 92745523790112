import {JSX} from 'react';

import {FrontendName} from '@shared/dynamo_model';
import {baseThemeNoReact} from '@shared/frontends/frontend_base_theme';
import {FrontendTheme} from '@shared/frontends/frontend_theme_model';
import {deepMerge} from '@shared/lib/object_utils';
import {DeepPartial} from '@shared/lib/type_utils';

export function createTheme(
  frontendName: FrontendName,
  overrides: DeepPartial<FrontendTheme>,
  extra: {logo: JSX.Element}
): FrontendTheme {
  const {logo} = extra;
  return deepMerge(
    deepMerge(baseThemeNoReact, {main: {frontendName}}),
    deepMerge(overrides, {main: {logo}})
  ) as FrontendTheme;
}
