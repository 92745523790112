export interface FrontendDomainConfig {
  domains: string[];
}

export const FRONTEND_STATIC_SUBDOMAIN = 'static';
export const FRONTEND_API_SUBDOMAIN = 'api';
export const FRONTEND_WWW_PREFIX = 'www.';

export function trimWwwSubdomain(value: string): string {
  return value.startsWith(FRONTEND_WWW_PREFIX) ? value.slice(FRONTEND_WWW_PREFIX.length) : value;
}

export function getStaticDomain(domain: string): string {
  return `//${FRONTEND_STATIC_SUBDOMAIN}.${trimWwwSubdomain(domain)}`;
}
