import {ReactNode, useMemo} from 'react';
import {Location, Route, useLocation} from 'react-router-dom';

import {SeoObject} from '@shared/frontends/ssr_context';

import {withSeo} from '@shared-frontend/components/core/with_seo';
import {withTracker} from '@shared-frontend/components/core/with_tracker';
import {ComponentClass, wrapElements} from '@shared-frontend/lib/react';
import {useSession} from '@shared-frontend/lib/session_store';

export interface RouteOpts {
  seo: SeoObject;
  useTracker?: boolean;
  wrapper?: ComponentClass | ComponentClass[];
  isDisabledHandler?: (opts: ShouldRenderOpts) => ComponentClass | undefined;
}

interface ShouldRenderOpts {
  session: ReturnType<typeof useSession>;
  location: Location;
}

// Handler for determining if a route should be rendered a 404 based on the session
export const sessionRequired =
  (c: ComponentClass) =>
  (opts: ShouldRenderOpts): ComponentClass | undefined =>
    'sessionId' in opts.session ? undefined : c;

// Same for super admin
export const superAdminSessionRequired =
  (c: ComponentClass) =>
  (opts: ShouldRenderOpts): ComponentClass | undefined =>
    'sessionId' in opts.session && opts.session.isAdmin ? undefined : c;

// Same for cse admin
export const cseAdminSessionRequired =
  (c: ComponentClass) =>
  (opts: ShouldRenderOpts): ComponentClass | undefined => {
    const groupId = /^\/cse-admin\/(?<groupId>[^/]+)(?:\/.*)?$/gu.exec(opts.location.pathname)
      ?.groups?.['groupId'];
    return 'sessionId' in opts.session && opts.session.adminGroups.find(g => g.groupId === groupId)
      ? undefined
      : c;
  };

export const useRoute = (path: string, component: ComponentClass, opts: RouteOpts): ReactNode => {
  const {seo, useTracker, wrapper = [], isDisabledHandler = () => undefined} = opts;

  // Determine if the route should be rendered
  const session = useSession();
  const location = useLocation();
  const DisabledComponent = useMemo(() => {
    const isDisabled = isDisabledHandler({session, location});
    return isDisabled;
  }, [isDisabledHandler, location, session]);

  // Wrap the component with the wrapper classes
  const WrappedClass = useMemo(() => {
    const classes = !DisabledComponent
      ? [...(Array.isArray(wrapper) ? wrapper : [wrapper]), component]
      : [DisabledComponent];
    const element = wrapElements(classes);
    return () => element;
  }, [DisabledComponent, component, wrapper]);

  // Wrap the component with SEO and Tracker
  const RouteComponent = useMemo(() => {
    const Component = withSeo(WrappedClass, seo);
    return useTracker ? withTracker(Component) : Component;
  }, [WrappedClass, seo, useTracker]);

  // Return the Route component
  return <Route key={path} path={path} Component={RouteComponent} />;
};
