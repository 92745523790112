import {useMemo} from 'react';

import {
  SessionItem,
  UserSessionItem,
  VisitorSessionItem,
} from '@shared/api/definitions/frontend_shared_api';
import {FrontendUserDataContentType} from '@shared/dynamo_model';
import {DataStoreApi} from '@shared/frontends/data_store_api';
import {useSsrContext} from '@shared/frontends/use_ssr_context';

import {createDataStore} from '@shared-frontend/lib/data_store';

type Force<T> = T extends undefined ? never : T;

export type TypedUserSessionItem<T extends FrontendUserDataContentType | undefined> = Omit<
  UserSessionItem,
  'userData'
> & {
  userData: T extends undefined ? undefined : Force<UserSessionItem['userData']> & {type: T};
};

type UserSession<T extends FrontendUserDataContentType | undefined> =
  | TypedUserSessionItem<T>
  | VisitorSessionItem;

let setSessionCalled = false;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sessionStore = createDataStore<UserSession<any>>();

export function createTypedSessionDataStore<
  T extends FrontendUserDataContentType | undefined,
>(): Omit<DataStoreApi<UserSession<T>, SessionItem>, 'getData'> & {
  useSessionRef: () => {current: UserSession<T>};
  getSession: () => UserSession<T> | undefined;
} {
  function typedSession(item: SessionItem): UserSession<T> {
    return item;
  }

  const sessionRef: {current: UserSession<T> | undefined} = {current: undefined};
  function useSessionRef(): {current: UserSession<T>} {
    const {initialSession} = useSsrContext();
    if (sessionRef.current === undefined && initialSession !== undefined) {
      sessionRef.current = typedSession(initialSession);
    }
    return sessionRef as {current: UserSession<T>};
  }

  function useSession(): UserSession<T> {
    const {initialSession} = useSsrContext();
    const convertedInitialSession = useMemo(
      () => (initialSession ? typedSession(initialSession) : undefined),
      [initialSession]
    );
    const storeSession = sessionStore.useData() as UserSession<T>;
    return setSessionCalled ? storeSession : (convertedInitialSession as UserSession<T>);
  }

  function getSession(): UserSession<T> | undefined {
    return sessionStore.getData();
  }

  const setSessionInternal = (newSession: SessionItem): void => {
    setSessionCalled = true;
    const newSessionConverted = typedSession(newSession);
    sessionStore.setData(newSessionConverted);
    sessionRef.current = newSessionConverted;
  };

  const updateSession = (fn: (session: UserSession<T>) => SessionItem): void => {
    setSessionInternal(fn(sessionStore.getData() as UserSession<T>));
  };

  return {
    setData: setSessionInternal,
    useData: useSession,
    updateData: updateSession,
    addChangeListener: sessionStore.addChangeListener,
    getSession,
    useSessionRef,
  };
}

export const sessionDataStore = createTypedSessionDataStore();
export const setSession = sessionDataStore.setData;
export const useSession = sessionDataStore.useData;
export const getSession = sessionDataStore.getSession;
export const useSessionRef = sessionDataStore.useSessionRef;
