import {createGlobalStyle} from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    font-weight: 400;
    font-family: ${p => p.theme.main.fontFamily};
    font-size: ${p => p.theme.main.fontSize}px;
    line-height: ${p => p.theme.main.lineHeight};
    background-color: ${p => p.theme.main.backgroundColor};
    color: ${p => p.theme.main.textColor};
  }

  [hidden] {
    display: none !important;
  }
`;
