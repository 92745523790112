import {WindowExtra} from '@shared/frontends/ssr_context';

// Unsafe casting of window as it can be undefined during SSR.
// Use this when you are sure your code will never run during SSR.
export function getWindowUnsafe(): Window & WindowExtra {
  return window;
}

// During SSR, window is undefined
export function getWindow(): (Window & WindowExtra) | undefined {
  return globalThis.window;
}
